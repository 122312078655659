import { api } from "../../../../helpers/topline-api";

export default async function saveNewContact(slug, contactInfoArr) {
  try {
    const contactsRes = await api.post(`/api/contacts`, {
      slug,
      contact: contactInfoArr,
    });

    if (contactsRes.data.message) return contactsRes.data.message;
  } catch (e) {
    return "error";
  }
}
