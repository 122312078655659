import React from "react";
import { formatTwoDecimals } from "../utils";

export interface TaxNameAndAmountProps {
  tax: any;
  setTax: (arg0: any) => void;
  noTaxNameError: boolean;
  setNoTaxNameError: (arg0: boolean) => void;
  setShowTaxField: (arg0: boolean) => void;
  setNoTaxAmountError: (arg0: boolean) => void;
  noTaxAmountError: boolean;
  setShowSendRequestButton: (arg0: boolean) => void;
}

export default function TaxNameAndAmount({
  tax,
  setTax,
  noTaxNameError,
  setNoTaxNameError,
  setNoTaxAmountError,
  noTaxAmountError,
  setShowSendRequestButton,
}: TaxNameAndAmountProps) {
  function onChangeHandler(e: any) {
    setNoTaxNameError(false);
    setNoTaxAmountError(false);
    setShowSendRequestButton(false);

    if (e.target.name === "taxAmount") {
      if (parseFloat(e.target.value) <= 100 || !e.target.value) {
        const taxAmount = formatTwoDecimals(e.target.value);
        setTax({ ...tax, [e.target.name]: taxAmount });
      }
    } else {
      setTax({ ...tax, [e.target.name]: e.target.value });
    }
  }

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row w-full justify-end">
          <div className="w-2/3 relative mr-2">
            <input
              type="text"
              name="taxName"
              id="taxName"
              className={`relative block w-full bg-white focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-sm-normal rounded-md shadow-sm ${
                noTaxNameError ? "border-red-600" : "border-gray-300"
              }`}
              placeholder="Tax name"
              onChange={(e) => onChangeHandler(e)}
              value={tax.taxName}
            />
          </div>
          <div className="w-1/3 relative">
            <input
              type="text"
              pattern="\d*"
              name="taxAmount"
              id="taxAmount"
              className={`relative block w-full px-3 border-gray-300 bg-white focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-sm-normal rounded-md shadow-sm ${
                noTaxAmountError ? "border-red-600" : "border-gray-300"
              }`}
              placeholder="0"
              onChange={(e) => onChangeHandler(e)}
              value={tax.taxAmount}
            />
            <div className="pointer-events-none absolute top-2.5 right-2 flex items-center z-50">
              <span className={`text-gray-500 pl-1 text-sm-normal`}>%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
