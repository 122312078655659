import React, { useContext, useEffect } from "react";
import { useState } from "react";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import { addLineItemDb } from "../utils";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";

export interface AddItemModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  lineItems: any[];
  addLineItem: (arg0: any, arg1: any) => void;
  addItemId: number;
  setAddItemId: (arg0: number) => void;
  setLineItems: (arg0: any[]) => void;
}

export default function AddItemModal({
  isOpen,
  setIsOpen,
  lineItems,
  addLineItem,
  addItemId,
  setAddItemId,
  setLineItems,
}: AddItemModalProps) {
  const { profileInfo } = useContext(DesktopContext);
  const { slug } = profileInfo || {};
  // const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  // const [requestType, setRequestType] = useState<string>("");
  const [searchedItem, setSearchedItem] = useState<string>("");
  const [displayedItems, setDisplayedItems] = useState<any[]>(lineItems);
  const [suggestedItems, setSuggestedItems] = useState<any[]>([]);

  useEffect(() => {
    setDisplayedItems(lineItems);
    setSuggestedItems([]);
    setSearchedItem("");
  }, [isOpen]);

  function onChangeHandler(e: any) {
    setSearchedItem(e["item-name"]);
    const input = e["item-name"]?.toLowerCase().trim();
    if (input.length > 0) {
      const newDisplayedItems = lineItems.filter((item) =>
        item.item?.toLowerCase()?.includes(input)
      );
      if (newDisplayedItems.length === 0) {
        setDisplayedItems([
          {
            item: e["item-name"],
          },
        ]);
        setSuggestedItems(lineItems);
      } else {
        setDisplayedItems(newDisplayedItems);
      }
    } else {
      // const newDisplayedItems = lineItems;
      setDisplayedItems(lineItems);
      setSuggestedItems([]);
    }
  }

  async function postLineItem(addItemId: any, itemName: any) {
    addLineItem(addItemId, itemName);
    const newLineItems = await addLineItemDb(slug, itemName);
    setLineItems(newLineItems);
  }

  const searchElement = (
    <div className="flex justify-center w-full sm:hidden">
      <div className="relative w-full">
        <DynamicInput
          name="item-name"
          placeHolder="Search or add items"
          // fieldValidator={validate}
          handleLocalEdits={onChangeHandler}
          value={searchedItem}
          edit={true}
          validationOn={true}
        />
      </div>
    </div>
  );

  return (
    <>
      <UtilityModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onCancelHandler={setIsOpen}
        headerElement={searchElement}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <ul role="list" className="divide-y divide-gray-200">
          {displayedItems.map((item) =>
            item.item ? (
              <li
                key={item.id}
                className="flex items-center py-4 focus-within:ring-2 focus-within:ring-red-500 hover:bg-blue-50 cursor-pointer px-6"
                onClick={
                  suggestedItems.length > 0
                    ? () => postLineItem(addItemId, item.item)
                    : () => addLineItem(addItemId, item.item)
                }
              >
                <div>
                  <p className="text-sm-medium text-gray-900 text-center">
                    {item.item}
                  </p>
                </div>
              </li>
            ) : (
              <></>
            )
          )}
        </ul>
      </UtilityModal>
    </>
  );
}
