import React, { useState } from "react";

import { XCircleIcon } from "@heroicons/react/outline";

import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export interface ItemsDropdownProps {
  id: number;
  maxId: number;
  items: any[];
  onChangeHandler: any;
  addLineItem: (arg0: number, arg1: any) => void;
  removeItem: (arg0: number) => void;
  lineItemNames: any[];
  setNoPriceError: (arg0: boolean) => void;
  noPriceError: boolean;
  comingFromExitModal: boolean;
  setComingFromExitModal: (arg0: boolean) => void;
}

export default function ItemsDropdown({
  id,
  maxId,
  items,
  onChangeHandler,
  addLineItem,
  removeItem,
  lineItemNames,
  noPriceError,
  setNoPriceError,
  comingFromExitModal,
  setComingFromExitModal,
}: ItemsDropdownProps) {
  const [query, setQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(items[id]?.itemName);

  function selectItem(selectedItem) {
    setSelectedItem(selectedItem);
    addLineItem(id, selectedItem);
  }

  function onChange(query) {
    setSelectedItem(query);
    setQuery(query);
    onChangeHandler(query, id, "name");
  }

  const filteredItems =
    query === ""
      ? lineItemNames.filter((item) => {
          return !!item;
        })
      : lineItemNames.filter((item) => {
          return item?.toLowerCase().includes(query.toLowerCase());
        });

  const [placeholder, setPlaceholder] = useState<string>("Item");

  function onInputFocus() {
    setPlaceholder("Search or add item");
  }
  function onInputBlur() {
    setPlaceholder("Item");
  }

  return (
    <>
      {items.length > 1 && (
        <div
          onClick={() => removeItem(id)}
          className="z-20 absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
        >
          <XCircleIcon className="text-gray-600 w-5 h-5" />
        </div>
      )}

      <Combobox
        as="div"
        value={items[id]?.itemName}
        onChange={(query) => onChange(query)}
      >
        <div className="relative">
          <Combobox.Button as="div">
            <Combobox.Input
              className={`relative sm:block hidden w-full rounded-none bg-white focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-body-normal sm:shadow-sm ${
                id === 0 && "rounded-tl-md"
              } ${id === maxId && "rounded-bl-md"} ${
                items.length > 1 && "pl-8"
              } ${noPriceError ? "border-red-600" : "border-gray-300"}`}
              onChange={(event) => onChange(event.target.value)}
              displayedValue={(item: string) => item}
              placeholder={placeholder}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          </Combobox.Button>

          {filteredItems.length > 0 ? (
            <Combobox.Options className="absolute z-100-important mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-body-normal">
              {filteredItems.map((item, idx) => (
                <Combobox.Option
                  key={idx}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    )
                  }
                  onClick={() => selectItem(selectedItem)}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "text-body-semibold"
                        )}
                      >
                        {item}
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          ) : (
            <Combobox.Options className="absolute z-100-important mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-body-normal shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Combobox.Option
                value={query}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  )
                }
                onClick={() => selectItem(selectedItem)}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "text-body-semibold"
                      )}
                    >
                      {query}
                    </span>
                  </>
                )}
              </Combobox.Option>
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
}
