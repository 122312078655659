import React, { useContext, useEffect, useState } from "react";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { XCircleIcon } from "@heroicons/react/outline";
import ItemField from "../ItemField/ItemField";
import { validatePhoneNumber } from "../../contacts/helpers/formatPhoneNumber";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  formatTwoDecimals,
  getCustomerDisplayName,
  numberWithCommas,
  stripCommas,
} from "../utils";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import AddItemModal from "../AddItemModal/AddItemModal";
import DiscountDropdown from "../DiscountDropdown/DiscountDropdown";
import TaxNameAndAmount from "../TaxNameAndAmount/TaxNameAndAmount";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";

export interface Stage2Props {
  type: string;
  recipient: any;
  recipientName: string;
  note: string;
  setNote: (arg0: string) => void;
  totalPrice: any;
  setTotalPrice: (arg0: any) => void;
  items: any;
  setItems: (arg0: any) => void;
  setStage: (arg0: any) => void;
  setSearchedContact: (arg0: any) => void;
  emailOrText: string;
  setEmailOrText: (arg0: string) => void;
  setDisplayedContacts: (arg0: any) => void;
  setNoPriceError: (arg0: boolean) => void;
  noPriceError: boolean;
  setShowSendRequestButton: (arg0: boolean) => void;
  lineItems: any[];
  setLineItems: (arg0: any[]) => void;
  showNoteField: boolean;
  showDiscountField: boolean;
  setShowDiscountField: (arg0: boolean) => void;
  showTaxField: boolean;
  setShowTaxField: (arg0: boolean) => void;
  discount: any;
  setDiscount: (arg0: any) => void;
  discountType: any;
  setDiscountType: (arg0: any) => void;
  tax: any;
  setTax: (arg0: any) => void;
  showAdvancedOptions: boolean;
  addNote: any;
  removeNote: any;
  fee: number;
  setFee: (arg0: any) => void;
  subtotal: number;
  setSubtotal: (arg0: any) => void;
  noTaxNameError: boolean;
  setNoTaxNameError: (arg0: boolean) => void;
  setNoTaxAmountError: (arg0: boolean) => void;
  noTaxAmountError: boolean;
  comingFromExitModal: boolean;
  setComingFromExitModal: (arg0: boolean) => void;
  editingQuote: boolean;
  convertingQuoteToInvoice: boolean;
  quoteToConvert: any;
  quotesFromDb: any;
  setOneMillionError: (arg0: boolean) => void;
  oneMillionError: boolean;
  noteError: boolean;
  setNoteError: (arg0: boolean) => void;
}

export default function Stage2({
  type,
  recipient,
  recipientName,
  note,
  setNote,
  totalPrice,
  setTotalPrice,
  items,
  setItems,
  setStage,
  setSearchedContact,
  emailOrText,
  setEmailOrText,
  setDisplayedContacts,
  noPriceError,
  setNoPriceError,
  setShowSendRequestButton,
  lineItems,
  setLineItems,
  showNoteField,
  showDiscountField,
  setShowDiscountField,
  showTaxField,
  setShowTaxField,
  discount,
  setDiscount,
  discountType,
  setDiscountType,
  tax,
  setTax,
  fee,
  setFee,
  subtotal,
  setSubtotal,
  noTaxNameError,
  setNoTaxNameError,
  setNoTaxAmountError,
  noTaxAmountError,
  comingFromExitModal,
  setComingFromExitModal,
  editingQuote,
  convertingQuoteToInvoice,
  quoteToConvert,
  quotesFromDb,
  oneMillionError,
  setOneMillionError,
  noteError,
  setNoteError,
}: Stage2Props) {
  const { profileInfo } = useContext(DesktopContext);
  const { who_pays_stripe_fees } = profileInfo || {};
  const [addItemModalOpen, setAddItemModalOpen] = useState<boolean>(false);
  const [addItemId, setAddItemId] = useState<number>(0);
  const [deleteItemsActive, setDeleteItemsActive] = useState<boolean>(false);

  useEffect(() => {
    if (quoteToConvert) {
      const quoteFromDb = quotesFromDb?.find(
        (i) => i.quote_id === quoteToConvert.id
      );
      if (quoteFromDb) {
        setEmailOrText(quoteFromDb?.request_type);
      } else {
        setEmailOrText("text");
      }
    } else {
      const emailText = checkEmailOrText();
      setEmailOrText(emailText);
    }
  }, [recipient, recipientName]);

  useEffect(() => {
    if (deleteItemsActive && items.length === 1) {
      setDeleteItemsActive(false);
    }
  }, [items]);

  function isNumeric(str) {
    return !isNaN(str);
  }

  function onChangeHandler(e: any, itemId: any, field: string) {
    setNoPriceError(false);
    setOneMillionError(false);
    setShowSendRequestButton(false);
    const item = items[itemId];

    if (field === "name") {
      const newItems = [...items];
      newItems[itemId] = {
        ...item,
        itemName: e,
      };
      setItems(newItems);
    } else if (field === "price") {
      const dollars = e.target.value;
      const formattedDollars = formatTwoDecimals(dollars);
      const dollarsNoCommas = stripCommas(formattedDollars);

      const regExp = /[a-zA-Z]/g;
      if (regExp.test(dollars)) {
        return;
      }

      if (isNumeric(dollarsNoCommas)) {
        const newItems = [...items];
        newItems[itemId] = {
          ...item,
          itemPrice: formattedDollars,
        };
        setItems(newItems);
      }
    }
  }

  function onBlurPriceHandler(e: any, itemId: any) {
    const item = items[itemId];
    const dollars = e.target.value;
    if (dollars !== "0" && dollars !== "") {
      const formattedPrice = numberWithCommas(stripCommas(dollars).toFixed(2));
      const newItems = [...items];
      newItems[itemId] = {
        ...item,
        itemPrice: formattedPrice,
      };
      setItems(newItems);
    }
  }
  function onChangeNote(e: any) {
    const noteLength = e["note-textarea"]?.length || 0;
    if (noteLength > 499) {
      setNoteError(true);
      return;
    }

    setNoteError(false);
    setNote(e["note-textarea"]);
  }

  function returnToStage1() {
    setStage("1");
    setDeleteItemsActive(false);
    setNoPriceError(false);
    setOneMillionError(false);
  }

  function checkEmailOrText() {
    if (recipient?.email && recipient?.phone_number) {
      return "both";
    } else if (recipient?.email && !recipient?.phone_number) {
      return "email";
    } else if (!recipient?.email && recipient?.phone_number) {
      return "text";
    } else if (validatePhoneNumber(recipientName)) {
      return "text";
    } else {
      return "email";
    }
  }
  function handleCloseAddItemModal() {
    setAddItemModalOpen(false);
    const inputs = [...items];
    const lastItemIndex = inputs.length - 1;
    const lastItem = inputs[lastItemIndex];
    if (!lastItem.itemName && inputs.length > 1) {
      inputs.pop();
      setItems(inputs);
    }
  }

  function addNewLineItem() {
    setShowSendRequestButton(false);
    const inputs = [...items];

    if (
      (items.length > 0 && (!!items[0].itemName || !!items[0].itemPrice)) ||
      items.length > 1
    ) {
      inputs.push({
        itemName: null,
        itemPrice: "",
      });
    }
    setItems(inputs);
    setAddItemModalOpen(true);
    const itemId =
      items.length === 1 && !items[0].itemName && !items[0].itemPrice
        ? 0
        : items.length;
    setAddItemId(itemId);
    setDeleteItemsActive(false);
  }

  function addNewLineItemDesktop() {
    setShowSendRequestButton(false);
    const inputs = [...items];
    inputs.push({
      itemName: null,
      itemPrice: "",
    });
    setItems(inputs);
    // const maxId = inputs.length - 1;
    // setTimeout(() => {
    //   focusInput(maxId);
    // }, 300);
  }

  function addLineItem(itemId: number, itemName: any) {
    const item = items[itemId];
    const newItems = [...items];
    newItems[itemId] = {
      ...item,
      itemName,
    };
    setItems(newItems);
    setAddItemModalOpen(false);
    const elementId = `price-${itemId}`;
    const element = document.getElementById(elementId);
    setTimeout(() => {
      element?.focus();
    }, 10);
    setTimeout(() => {
      element?.click();
    }, 10);
  }

  function removeTax() {
    setShowTaxField(false);
    setTax({ taxName: "", taxAmount: "" });
  }
  function removeDiscount() {
    setShowDiscountField(false);
    setDiscount("");
    setDiscountType("%");
  }

  const disabledStyle =
    "cursor-not-allowed border-gray-200 bg-gray-50 text-gray-600";

  return (
    <>
      <AddItemModal
        isOpen={addItemModalOpen}
        setIsOpen={handleCloseAddItemModal}
        lineItems={lineItems}
        setLineItems={setLineItems}
        addLineItem={addLineItem}
        addItemId={addItemId}
        setAddItemId={setAddItemId}
      />
      <div className="relative z-50 flex flex-col justify-between h-auto sm:pb-0 sm:mb-0 sm:min-h-400 overflow-x-visible">
        <div
          className={`flex sm:flex-row flex-col w-full h-full sm:mt-2 overflow-x-visible overflow-y-auto sm:overflow-visible`}
        >
          <div className="flex flex-col grow h-full sm:w-3/5 w-full sm:py-2 sm:mr-12">
            <div className="text-gray-700 text-sm-medium p-0.5">Customer</div>
            <div className="flex flex-row mb-2 p-0.5">
              <input
                className={`relative block w-full text-body-normal border border-gray-300 rounded-md p-2 sm:mr-0.5 px-3 cursor-pointer shadow-sm ${
                  editingQuote || convertingQuoteToInvoice
                    ? disabledStyle
                    : "sm:bg-white text-gray-900"
                }`}
                disabled={editingQuote || convertingQuoteToInvoice}
                onClick={returnToStage1}
                readOnly={true}
                value={getCustomerDisplayName(recipientName)}
              />
            </div>
            <div className="text-gray-700 text-sm-medium p-0.5">Item</div>
            <div className="flex flex-col w-full z-40">
              <div className="flex flex-row mb-1 z-40">
                <div className="flex flex-col w-full no-wrap p-0.5 z-40">
                  {items.map((item, index) => {
                    return (
                      <ItemField
                        key={index}
                        id={index}
                        item={item}
                        onChangeHandler={onChangeHandler}
                        onBlurPriceHandler={onBlurPriceHandler}
                        maxId={items.length - 1}
                        addItemModalOpen={addItemModalOpen}
                        setAddItemModalOpen={setAddItemModalOpen}
                        setAddItemId={setAddItemId}
                        items={items}
                        setItems={setItems}
                        deleteItemsActive={deleteItemsActive}
                        lineItems={lineItems}
                        addLineItem={addLineItem}
                        setShowSendRequestButton={setShowSendRequestButton}
                        noPriceError={noPriceError}
                        setNoPriceError={setNoPriceError}
                        comingFromExitModal={comingFromExitModal}
                        setComingFromExitModal={setComingFromExitModal}
                      />
                    );
                  })}
                </div>
              </div>
              {noPriceError && (
                <div className="text-sm-normal text-red-600 mb-2">Required</div>
              )}
              {oneMillionError && (
                <div className="text-sm-normal text-red-600 mb-2">
                  Please request an amount less than $1,000,000
                </div>
              )}
              <div className="flex w-full justify-between my-1">
                <div className="block sm:hidden">
                  <SecondaryButton
                    text="Add item"
                    size="md"
                    onClickFunc={addNewLineItem}
                  />
                </div>
                <div className="hidden sm:block">
                  <SecondaryButton
                    text="Add item"
                    size="md"
                    onClickFunc={addNewLineItemDesktop}
                  />
                </div>
                {items.length > 1 && (
                  <PrimaryLink
                    text={deleteItemsActive ? "Done" : "Delete items"}
                    size="md"
                    onClickFunc={() =>
                      setDeleteItemsActive(deleteItemsActive ? false : true)
                    }
                    customStyle="sm:hidden"
                  />
                )}
              </div>
              {showNoteField && (
                <div className="mt-2">
                  <div className="text-gray-700 text-sm-medium p-0.5">Note</div>
                  <div className="p-0.5">
                    <DynamicInput
                      name="note-textarea"
                      value={note}
                      handleLocalEdits={(e: any) => onChangeNote(e)}
                      textarea
                      edit
                      rows={3}
                      size="md"
                      hint="500 characters max"
                    />
                  </div>
                  {noteError && (
                    <div className="flex text-sm-normal text-red-600">
                      Note must be less than 500 characters
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col sm:w-2/5 w-full sm:py-2 sm:mb-0">
            <div className="hidden sm:flex flex-col space-y-3 pt-2 pb-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex text-sm-medium text-gray-700">
                  Subtotal
                </div>
                <div className="flex">
                  ${numberWithCommas(subtotal.toFixed(2))}
                </div>
              </div>

              {showDiscountField ? (
                <div className="flex flex-row justify-between items-center">
                  <div className="flex text-sm-medium text-gray-700">
                    <div
                      onClick={removeDiscount}
                      className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                    >
                      <XCircleIcon className="text-gray-600 w-5 h-5" />
                    </div>
                    <div>Discount</div>
                  </div>
                  <DiscountDropdown
                    discount={discount}
                    setDiscount={setDiscount}
                    discountType={discountType}
                    setDiscountType={setDiscountType}
                  />
                </div>
              ) : (
                <PrimaryLink
                  text="Add Discount"
                  size="md"
                  onClickFunc={() => setShowDiscountField(true)}
                />
              )}

              {showTaxField ? (
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between items-center w-full">
                    <div className="flex text-sm-medium text-gray-700 items-center mr-4 w-1/5">
                      <div
                        onClick={removeTax}
                        className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                      >
                        <XCircleIcon className="text-gray-600 w-5 h-5" />
                      </div>
                      <div>Tax</div>
                    </div>
                    <div className="flex w-4/5">
                      <TaxNameAndAmount
                        tax={tax}
                        setTax={setTax}
                        setShowTaxField={setShowTaxField}
                        noTaxNameError={noTaxNameError}
                        setNoTaxNameError={setNoTaxNameError}
                        noTaxAmountError={noTaxAmountError}
                        setNoTaxAmountError={setNoTaxAmountError}
                        setShowSendRequestButton={setShowSendRequestButton}
                      />
                    </div>
                  </div>
                  {(noTaxNameError || noTaxAmountError) && (
                    <>
                      <div className="flex flex row w-full">
                        <div className="flex w-1/5 mr-4"></div>
                        <div className="flex flex-row w-4/5">
                          <div className="w-2/3 relative mt-1">
                            <div className="flex text-sm-normal text-red-600">
                              Required
                            </div>
                          </div>
                          <div className="w-1/3 relative"></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <PrimaryLink
                  text="Add Tax"
                  size="md"
                  onClickFunc={() => setShowTaxField(true)}
                />
              )}

              {who_pays_stripe_fees === "customer" &&
                (type !== "quote" || convertingQuoteToInvoice) && (
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex text-sm-medium text-gray-700">
                      Processing Fee
                    </div>
                    <div>${numberWithCommas(fee.toFixed(2))}</div>
                  </div>
                )}
            </div>
            <div className="hidden sm:flex flex-row items-center justify-between pt-3 sm:border-t border-gray-300">
              <div className="text-gray-600 text-sm">
                {type === "quote" && !convertingQuoteToInvoice && "Est."} Total
              </div>
              <div className="flex text-gray-900 text-h2-bold">
                ${numberWithCommas(totalPrice.toFixed(2))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
