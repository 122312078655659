import React from "react";
import EmptyState from "../../../uiwrappers/EmptyState/EmptyState";

export interface EmptyStateRequestPaymentProps {
  type: string;
  onCancelHandler: any;
  setAddRecipientModalOpen: any;
  setRequestPaymentModalOpen: any;
  newContact: any;
  setNewContact: (arg0: any) => void;
  setRecipient: (arg0: any) => void;
  setStage: (arg0: string) => void;
  setError: (arg0: boolean) => void;
  searchedContact: string;
  setSearchedContact: (arg0: any) => void;
  contactOnClickHandler: any;
}

export default function EmptyStateRequestPayment({
  type,
  newContact,
  searchedContact,
  contactOnClickHandler,
}: EmptyStateRequestPaymentProps) {
  return (
    <div className="flex flex-col items-center w-full sm:h-300">
      {!searchedContact ? (
        <EmptyState
          header="No contacts"
          subheader="Get started by creating your first contact OR enter a phone number or email above"
          icon="photo"
        />
      ) : (
        <div className="flex flex-row justify-center w-full">
          <div className="relative flex flex-col justify-between h-auto w-full mb-16 sm:mb-0">
            <div
              className="flex hover:bg-blue-50 px-8 py-4 cursor-pointer border-b border-gray-300"
              onClick={() => contactOnClickHandler(newContact)}
            >
              <div className="flex flex-col justify-center w-full">
                <div className="text-sm-normal flex justify-start">
                  Send {type === "invoice" ? "payment request" : "quote"} to
                </div>
                <div className="text-sm-medium flex justify-start">
                  {newContact}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
