import React from "react";
import { formatTwoDecimals } from "../utils";

export default function DiscountDropdown({
  discount,
  setDiscount,
  discountType,
  setDiscountType,
}: {
  discount: string;
  setDiscount: React.Dispatch<React.SetStateAction<string>>;
  discountType: string;
  setDiscountType: React.Dispatch<React.SetStateAction<string>>;
}) {
  function handleChangeType(e) {
    setDiscountType(e.target.value);
  }
  function handleChangeDiscount(e) {
    if (parseFloat(e.target.value) <= 100 || !e.target.value) {
      const discount = formatTwoDecimals(e.target.value);
      setDiscount(discount);
    }
  }
  return (
    <div className="w-2/5 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 flex items-center">
        <label htmlFor="discount" className="sr-only">
          Discount
        </label>
        <select
          id="discount-type"
          name="discount-type"
          autoComplete="discount"
          className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-8 text-gray-500 focus:border-blue-600 focus:ring-blue-600 sm:text-sm"
          onChange={(e) => handleChangeType(e)}
          value={discountType}
        >
          <option>%</option>
          <option>$</option>
        </select>
      </div>
      <input
        type="text"
        pattern="\d*"
        name="discount"
        id="discount"
        className="block w-full rounded-md border-gray-300 pl-16 focus:border-blue-600 focus:ring-blue-600 sm:text-sm"
        placeholder="0"
        onChange={(e) => handleChangeDiscount(e)}
        value={discount}
      />
    </div>
  );
}
