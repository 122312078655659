import React, { useContext, useEffect, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import EmptyStateRequestPayment from "../EmptyStateRequestPayment/EmptyStateRequestPayment";
import Stage1 from "../Stage1/Stage1";
import Stage2 from "../Stage2/Stage2";
import AddContactModal from "../AddContactModal/AddContactModal";
import {
  cancelQuote,
  createInvoice,
  createQuote,
  createStripeConnectCustomer,
  finalizeInvoice,
  formatEmailforStripe,
  formatPhoneforStripe,
  getAllInvoices,
  getAllQuotes,
  getCustomerDisplayName,
  initalItemState,
  numberWithCommas,
  searchStripeCustomer,
  seedPaymentItems,
  sendEmailOrTextInvoice,
  sendEmailOrTextQuote,
  stripCommas,
} from "../utils";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../contacts/helpers/formatPhoneNumber";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";

import useIsMobile from "../../../../hooks/useIsMobile";
import { PaymentsContext } from "../PaymentsContextProvider/PaymentsContextProvider";
import ActionModal from "../../../common/ActionModal/ActionModal";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import RedLink from "../../../uiwrappers/RedLink/RedLink";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import PaymentsSubmitButton from "../../../uiwrappers/PaymentsSubmitButton/PaymentsSubmitButton";
import TaxNameAndAmount from "../TaxNameAndAmount/TaxNameAndAmount";
import { XCircleIcon } from "@heroicons/react/outline";
import DiscountDropdown from "../DiscountDropdown/DiscountDropdown";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { IContact } from "../../contacts/Contact/types";

export interface RequestPaymentModalProps {
  requestPaymentModalOpen: boolean;
  setRequestPaymentModalOpen: (arg0: boolean) => void;
  onCancelHandler: () => void;
  searchedContact: any;
  setSearchedContact: any;
  recipient: any;
  setRecipient: (arg0: any) => void;
  displayedContacts: any[];
  setDisplayedContacts: (arg0: any[]) => void;
  openInvoices?: any[];
  openQuotes?: any[];
  acceptedQuotes?: any[];
  setAcceptedQuotes?: (arg0: any[]) => void;
  setOpenInvoices?: (arg0: any[]) => void;
  setOpenQuotes?: (arg0: any[]) => void;
  type: string;
  startOnStage2?: boolean;
  quoteToConvert?: any;
  setQuoteToConvert?: (arg0: any) => void;
  quoteToEdit?: any;
  setQuoteToEdit?: (arg0: any) => void;
  setIsPaymentDetailModalOpen?: any;
  quotesFromDb?: any[];
}

export default function RequestPaymentModal({
  requestPaymentModalOpen,
  setRequestPaymentModalOpen,
  onCancelHandler,
  searchedContact,
  setSearchedContact,
  recipient,
  setRecipient,
  displayedContacts,
  setDisplayedContacts,
  openInvoices,
  setOpenInvoices,
  type,
  openQuotes,
  setOpenQuotes,
  acceptedQuotes,
  setAcceptedQuotes,
  startOnStage2,
  quoteToConvert,
  setQuoteToConvert,
  quoteToEdit,
  setQuoteToEdit,
  setIsPaymentDetailModalOpen,
  quotesFromDb,
}: RequestPaymentModalProps) {
  const {
    contacts,
    setContacts,
    profileInfo,
    setAllUserStripeInvoices,
    downloadAppOnIphoneWithNoHomeButton,
  } = useContext(DesktopContext);

  const {
    slug,
    stripe_connect_account_id,
    who_pays_stripe_fees,
    default_tax_on,
    default_tax_custom_type,
    default_tax_type,
    default_tax_rate,
  } = profileInfo || {};

  const { setInvoices, quotes, setQuotes } = useContext(PaymentsContext);

  const defaultTaxType =
    default_tax_type === "Custom" ? default_tax_custom_type : default_tax_type;
  const defaultTaxSettings = default_tax_on
    ? { taxName: defaultTaxType, taxAmount: default_tax_rate }
    : { taxName: "", taxAmount: "" };

  const [stage, setStage] = useState<string>("1");
  const [newContact, setNewContact] = useState<string>("");
  const [recipientName, setRecipientName] = useState<string>("");
  const [addRecipientModalOpen, setAddRecipientModalOpen] =
    useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [items, setItems] = useState(initalItemState);
  const [showSendRequestButton, setShowSendRequestButton] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState<string>("");
  const [emailOrText, setEmailOrText] = useState<string>("");
  const [validationError, setValidationError] = useState<boolean>(false);
  const [noteError, setNoteError] = useState<boolean>(false);
  const [noPriceError, setNoPriceError] = useState<boolean>(false);
  const [noTaxNameError, setNoTaxNameError] = useState<boolean>(false);
  const [noTaxAmountError, setNoTaxAmountError] = useState<boolean>(false);
  const [oneMillionError, setOneMillionError] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<any[]>([]);
  const [showNoteField, setShowNoteField] = useState<boolean>(false);
  const [showAdvancedOptions, setShowAdvancedOptions] =
    useState<boolean>(false);
  const [showDiscountField, setShowDiscountField] = useState<boolean>(false);
  const [showTaxField, setShowTaxField] = useState<boolean>(
    default_tax_on ? true : false
  );
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [discount, setDiscount] = useState<any>("");
  const [discountType, setDiscountType] = useState<any>("%");
  const [tax, setTax] = useState<any>(defaultTaxSettings);
  const [fee, setFee] = useState<any>(0);
  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);
  const [comingFromExitModal, setComingFromExitModal] =
    useState<boolean>(false);
  const [comingFromAddContactModal, setComingFromAddContactModal] =
    useState<boolean>(false);
  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  const [requestType, setRequestType] = useState<string>("");

  const firstName = recipient?.first_name ? recipient.first_name : "";
  const isMobile = useIsMobile();
  const [alertMessage, setAlertMessage] = useState("");

  const router = useRouter();

  useEffect(() => {
    if (router.query.paymentRecipient) {
      const id = router.query.paymentRecipient;
      const contact = contacts.find((c) => c.id == id);
      if (contact) {
        setRecipient(contact);
        setStage("2");
        setRecipientDisplayName(`${contact.first_name} ${contact.last_name}`);
      }
    }
  }, []);

  useEffect(() => {
    if (
      requestPaymentModalOpen &&
      !comingFromExitModal &&
      !comingFromAddContactModal
    ) {
      (async () => {
        const items = await seedPaymentItems(slug);
        if (items) {
          setLineItems(items);
          if (!startOnStage2) setInitialState();
        } else {
          setLineItems([]);
        }
      })();
    }
  }, [requestPaymentModalOpen]);

  useEffect(() => {
    if (startOnStage2 && requestPaymentModalOpen) {
      setStage("2");
      if (quoteToConvert || quoteToEdit) {
        const quote = quoteToConvert ? quoteToConvert : quoteToEdit;
        const itemsWithoutProcessingFee =
          quote?.computed?.upfront?.line_items?.data?.filter(
            (item: any) => item?.description !== "Processing Fee"
          );
        const items = itemsWithoutProcessingFee.map((item: any) => {
          return {
            itemName: item?.description,
            itemPrice: (item?.amount_subtotal / 100).toFixed(2),
          };
        });
        setItems(items);
        const tax =
          quote?.total_details?.breakdown?.taxes?.length > 0 ? true : false;
        if (tax) {
          setShowTaxField(true);
          const taxDetails = quote?.total_details?.breakdown?.taxes[0];
          const taxAmount = taxDetails?.rate?.percentage;
          const taxName = taxDetails?.rate?.display_name;
          setTax({
            taxName: taxName,
            taxAmount: taxAmount,
          });
        } else {
          setShowTaxField(false);
          setTax({ taxName: "", taxAmount: "" });
        }
        const discount =
          quote?.total_details?.breakdown?.discounts?.length > 0 ? true : false;
        if (discount) {
          setShowDiscountField(true);
          const discountAmount =
            quote?.total_details?.breakdown?.discounts[0]?.discount?.coupon
              ?.amount_off / 100 || 0;
          const discountPercent =
            quote?.total_details?.breakdown?.discounts[0]?.discount?.coupon
              ?.percent_off || 0;

          if (discountAmount) {
            setDiscount(discountAmount);
            setDiscountType("$");
          } else {
            setDiscount(discountPercent);
            setDiscountType("%");
          }
        }
      }
    }
  }, [requestPaymentModalOpen]);

  function setInitialState() {
    if (!router.query.paymentRecipient) {
      setAlertMessage("");
      setIsPaymentDetailModalOpen && setIsPaymentDetailModalOpen(false);
      setTax(defaultTaxSettings);
      setDiscount("");
      setDiscountType("%");
      setSubtotal(0);
      setTotalPrice(0);
      setShowNoteField(false);
      setShowAdvancedOptions(false);
      setShowDiscountField(false);
      setShowTaxField(default_tax_on ? true : false);
      setStage("1");
      setNote("");
      setItems(initalItemState);
      setSubtotal(0);
      setDisplayedContacts(contacts);
      setComingFromAddContactModal(false);
      setNoPriceError(false);
      setNoteError(false);
      setOneMillionError(false);
      setRecipientName("");
      setRecipient({});
      setSearchedContact("");
      setComingFromExitModal(false);
      setValidationError(false);
      setQuoteToConvert && setQuoteToConvert(null);
      setQuoteToEdit && setQuoteToEdit(null);
    }
  }

  useEffect(() => {
    if (subtotal === 0) {
      setFee(0);
      return;
    }
    if (
      who_pays_stripe_fees === "customer" &&
      (type !== "quote" || !!quoteToConvert)
    ) {
      let priceWithDiscountAndTax = subtotal;

      if (discount) {
        if (discountType === "$") {
          priceWithDiscountAndTax = subtotal - discount;
        } else if (discountType === "%") {
          priceWithDiscountAndTax = subtotal * (1 - discount / 100);
        }
      }
      const taxPercentage = tax.taxAmount ? parseFloat(tax.taxAmount) / 100 : 0;
      const taxAmount = priceWithDiscountAndTax * taxPercentage;
      priceWithDiscountAndTax = priceWithDiscountAndTax + taxAmount;
      const feeTotal =
        (priceWithDiscountAndTax + 0.3) / 0.971 - priceWithDiscountAndTax;
      setFee(feeTotal);
    } else {
      setFee(0);
    }
    calculateTotalPrice();
  }, [subtotal, tax, discount, discountType]);

  useEffect(() => {
    calculateTotalPrice();
  }, [subtotal, fee, discount, discountType, tax, items]);

  function calculateTotalPrice() {
    let price = 0;
    items.forEach((item: any) => {
      const itemPrice = stripCommas(item?.itemPrice);
      price = price + parseFloat(itemPrice || 0);
    });
    const subtotal = price;
    setSubtotal(subtotal);

    if (discount) {
      if (discountType === "$") {
        price = price - discount;
        if (fee) {
          price = price + fee;
        }
      } else if (discountType === "%") {
        if (type === "invoice" || !!quoteToConvert) {
          let totalDiscount = 0;
          items.forEach((item: any) => {
            totalDiscount =
              totalDiscount +
              (discount / 100) * parseFloat(stripCommas(item?.itemPrice) || 0);
            totalDiscount = parseFloat(totalDiscount.toFixed(2));
          });
          price = price - totalDiscount;
        } else {
          price = price * (1 - discount / 100);
          price = parseFloat(price.toFixed(2));
        }
      }
    }

    const { taxAmount } = tax;
    if (taxAmount) {
      const taxPercentage = parseFloat(taxAmount) / 100;
      let totalTax = 0;
      if (discount && discountType === "%") {
        items.forEach((item: any) => {
          const itemPriceAfterDiscount =
            (1 - discount / 100) *
            parseFloat(stripCommas(item?.itemPrice) || 0);
          totalTax = totalTax + taxPercentage * itemPriceAfterDiscount;
          totalTax = parseFloat(totalTax.toFixed(2));
        });
      } else if (discount && discountType === "$") {
        if (!fee) {
          items.forEach((item: any) => {
            const itemPriceAfterDiscount = parseFloat(
              (
                parseFloat(stripCommas(item?.itemPrice) || 0) -
                discount *
                  (parseFloat(stripCommas(item?.itemPrice) || 0) / subtotal)
              ).toFixed(2)
            );
            totalTax = parseFloat(
              (totalTax + itemPriceAfterDiscount * taxPercentage).toFixed(2)
            );
          });
        } else {
          items.forEach((item: any) => {
            const itemAsPercentageOfSubtotal =
              parseFloat(stripCommas(item?.itemPrice) || 0) / (subtotal + fee);
            const itemPriceAfterDiscount = parseFloat(
              (
                parseFloat(stripCommas(item?.itemPrice) || 0) -
                discount * itemAsPercentageOfSubtotal
              ).toFixed(2)
            );
            const itemTax = parseFloat(
              (itemPriceAfterDiscount * taxPercentage).toFixed(2)
            );
            totalTax = totalTax + itemTax;
          });
        }
      } else if (!discount) {
        items.forEach((item: any) => {
          totalTax =
            totalTax +
            taxPercentage * parseFloat(stripCommas(item?.itemPrice) || 0);
          totalTax = parseFloat(totalTax.toFixed(2));
        });
      }
      price = price + totalTax;
      price = parseFloat(price.toFixed(2));
    }

    if (fee && (!discount || discountType !== "$")) {
      price = fee + price;
    }

    if (price < 0) {
      price = 0;
    }

    setTotalPrice(price);
  }

  useEffect(() => {
    if (!!contacts && contacts.length > 0) {
      const newContacts = contacts.sort(function (a, b) {
        const lastA = a?.last_name?.toUpperCase();
        const lastB = b?.last_name?.toUpperCase();
        if (lastA < lastB) {
          return -1;
        }
        if (lastA > lastB) {
          return 1;
        }
        return 0;
      });
      setDisplayedContacts(newContacts);
    }
  }, [contacts]);

  useEffect(() => {
    setShowSendRequestButton(false);
  }, [stage]);

  useEffect(() => {
    if (!comingFromExitModal && !comingFromAddContactModal && !startOnStage2) {
      setInitialState();
    }
  }, [requestPaymentModalOpen, addRecipientModalOpen]);

  function onChangeHandler(e: any) {
    setAlertMessage("");
    setValidationError(false);
    setSearchedContact(e["payment-recipient"]);

    const input = e["payment-recipient"].toLowerCase().trim();
    if (input.length > 0) {
      const newContacts = contacts.filter(
        (contact) =>
          contact.first_name?.toLowerCase().includes(input) ||
          contact.last_name?.toLowerCase().includes(input) ||
          (contact.first_name + " " + contact.last_name)
            .toLowerCase()
            .includes(input) ||
          contact.email?.toLowerCase().includes(input) ||
          contact.phone_number?.toLowerCase().includes(input)
      );
      if (newContacts.length > 0) {
        setDisplayedContacts(newContacts);
      } else {
        setDisplayedContacts(newContacts);
        setNewContact(e["payment-recipient"]);
      }
    } else {
      const newContacts = contacts;
      setDisplayedContacts(newContacts);
    }
  }

  function handleEnterKeydown(e: any) {
    if (displayedContacts.length > 1 || !searchedContact) {
      return;
    } else if (displayedContacts.length === 1) {
      contactOnClickHandler(displayedContacts[0]);
    } else if (displayedContacts.length === 0) {
      contactOnClickHandler(newContact);
    }
  }

  function recipientValidate(value: string) {
    value = typeof value === "string" ? value.trim() : value;
    if (
      typeof value === "string" &&
      value !== "" &&
      !validatePhoneNumber(value) &&
      !validateEmail(value) &&
      displayedContacts.length === 0
    ) {
      setAlertMessage("Please enter a valid email or phone number");
      return 0;
    }
    return 1;
  }

  function setRecipientDisplayName(recipient) {
    if (typeof recipient === "string") {
      setRecipientName(recipient);
    } else if (
      !recipient?.first_name &&
      !recipient?.last_name &&
      (recipient?.phone_number || recipient?.email)
    ) {
      if (recipient?.phone_number) setRecipientName(recipient?.phone_number);
      if (recipient?.email) setRecipientName(recipient?.email);
    } else {
      setRecipientName(`${recipient?.first_name} ${recipient?.last_name}`);
    }
  }

  useEffect(() => {
    setRecipientDisplayName(recipient);
  }, [recipient]);

  async function sendRequest(requestType: string) {
    try {
      // 1. Check to see if customer exists; if so, get customerId
      const recipientEmail = recipient?.email?.trim();
      const recipientPhone = recipient?.phone_number?.trim();
      let email, phone;
      email = formatEmailforStripe(recipientName.trim(), recipientEmail);
      phone = formatPhoneforStripe(recipientName.trim(), recipientPhone);

      // if customer has been deleted after creating quote and before requesting payment
      if (!email && !phone && !!quoteToConvert) {
        email = quoteToConvert.customer.email;
        phone = quoteToConvert.customer.phone;
      }
      const responseObj = await searchStripeCustomer(
        email,
        phone,
        slug,
        recipient
      );

      let customerId = responseObj?.id;
      let newContact = responseObj?.new_contact;
      // 2. If no customer, create customer, store customerId
      if (customerId === "none") {
        const name = recipient?.first_name
          ? `${recipient?.first_name} ${recipient?.last_name}`
          : recipientName;
        const newCustomer = await createStripeConnectCustomer(
          slug,
          newContact ? newContact : recipient,
          name,
          email,
          phone
        );
        customerId = newCustomer?.id;
        if (newContact) {
          newContact = { ...newContact, stripe_customer_id: customerId };
        }
      }

      if (newContact) setContacts([...contacts, newContact]);

      const { taxAmount, taxName } = tax;

      const itemsNoCommas = items.map((item) => {
        return { ...item, itemPrice: stripCommas(item.itemPrice) };
      });

      // 3. Using customerId, send invoice/quote to customer (use customerId and stripeConnectAccountId)
      let response;
      if (type === "invoice") {
        response = await createInvoice(
          customerId,
          slug,
          note,
          stripe_connect_account_id,
          itemsNoCommas,
          discount,
          discountType,
          taxAmount,
          taxName,
          fee
        );
        if (!response) {
          toast.error("Unable to send payment request. Please try again.");
          setRequestPaymentModalOpen(false);
          setInitialState();
          return;
        }
      }
      if (type === "quote") {
        if (!quoteToEdit && !quoteToConvert) {
          // create quote
          response = await createQuote(
            customerId,
            slug,
            note,
            stripe_connect_account_id,
            itemsNoCommas,
            discount,
            discountType,
            taxAmount,
            taxName,
            requestType
          );
          if (!response) {
            toast.error("Unable to send quote. Please try again.");
            setRequestPaymentModalOpen(false);
            setInitialState();
            return;
          }
        } else if (!!quoteToEdit && !quoteToConvert) {
          // edit quote (cancel and then create quote)
          const quoteId = quoteToEdit.id;
          await cancelQuote(quoteId, stripe_connect_account_id);
          response = await createQuote(
            customerId,
            slug,
            note,
            stripe_connect_account_id,
            itemsNoCommas,
            discount,
            discountType,
            taxAmount,
            taxName,
            requestType
          );
          if (!response) {
            toast.error("Unable to send quote. Please try again.");
            setRequestPaymentModalOpen(false);
            setInitialState();
            return;
          }
        } else if (!quoteToEdit && !!quoteToConvert) {
          // finalize invoice (former quote)
          const invoiceId = quoteToConvert.invoice.id;
          response = await finalizeInvoice(
            invoiceId,
            customerId,
            slug,
            note,
            stripe_connect_account_id,
            itemsNoCommas,
            discount,
            discountType,
            taxAmount,
            taxName,
            requestType,
            fee
          );
          if (!response) {
            toast.error("Unable to send payment request. Please try again.");
            setRequestPaymentModalOpen(false);
            setInitialState();
            return;
          }
        }
      }

      const invoice = response?.invoice;
      const quote = response?.quote;
      const bitlyLink = response?.bitly_url;

      // 4. Set state
      if (type === "invoice") {
        const newOpenInvoices = openInvoices ? [...openInvoices] : [];
        newOpenInvoices.unshift(invoice);
        setOpenInvoices && setOpenInvoices(newOpenInvoices);
      }
      if (type === "quote") {
        if (!quoteToEdit && !quoteToConvert) {
          const newOpenQuotes = openQuotes ? [...openQuotes] : [];
          newOpenQuotes.unshift(quote);
          setOpenQuotes && setOpenQuotes(newOpenQuotes);
        } else if (!!quoteToEdit && !quoteToConvert) {
          const newOpenQuotes = openQuotes ? [...openQuotes] : [];
          const quoteIndex = newOpenQuotes.findIndex(
            (q) => q.id === quoteToEdit.id
          );
          newOpenQuotes.splice(quoteIndex, 1);
          newOpenQuotes.unshift(quote);
          setOpenQuotes && setOpenQuotes(newOpenQuotes);
        } else if (!quoteToEdit && !!quoteToConvert) {
          const newAcceptedQuotes = acceptedQuotes ? [...acceptedQuotes] : [];
          const quoteIndex = newAcceptedQuotes.findIndex(
            (q) => q.invoice.id === quoteToConvert.invoice.id
          );
          newAcceptedQuotes.splice(quoteIndex, 1);
          setAcceptedQuotes && setAcceptedQuotes(newAcceptedQuotes);
        }
      }

      // 5. Send email/text to homeowner
      const recipientId = recipient?.id;
      const invoiceId = invoice?.id;
      const quoteId = quote?.id;
      let emailTextResponse;

      if (type === "invoice" && !quoteToConvert) {
        const quoteId = null;
        emailTextResponse = await sendEmailOrTextInvoice(
          recipientId,
          slug,
          requestType,
          bitlyLink,
          totalPrice,
          recipientName,
          taxAmount,
          quoteId,
          invoiceId
        );
      } else if (type === "quote" && !!quoteToConvert) {
        const quoteId = quoteToConvert.id;
        emailTextResponse = await sendEmailOrTextInvoice(
          recipientId,
          slug,
          requestType,
          bitlyLink,
          totalPrice,
          recipientName,
          taxAmount,
          quoteId,
          invoiceId
        );
      } else if (type === "quote") {
        emailTextResponse = await sendEmailOrTextQuote(
          recipientId,
          slug,
          requestType,
          bitlyLink,
          totalPrice,
          recipientName,
          taxAmount,
          quoteId
        );
      }

      // 5. Set state (payments context)
      if (stripe_connect_account_id) {
        if (type === "invoice" || !!quoteToConvert) {
          const allInvoices = await getAllInvoices(stripe_connect_account_id);
          setInvoices(allInvoices);
          setAllUserStripeInvoices(allInvoices);
        }
        if (type === "quote" && !quoteToConvert) {
          const allQuotes = await getAllQuotes(stripe_connect_account_id);
          setQuotes(allQuotes);
        }
      }

      setRequestPaymentModalOpen(false);
      setInitialState();

      // 6. Toast
      if (type === "invoice" && !quoteToConvert) {
        if (emailTextResponse?.status === 201) {
          toast.success("Payment request sent");
        }
      } else if (type === "quote" && !!quoteToConvert) {
        if (emailTextResponse?.status === 201) {
          toast.success("Payment requested and moved to Requests tab");
        }
      } else if (type === "quote" && !quoteToConvert) {
        if (emailTextResponse?.status === 201) {
          toast.success("Quote sent");
        }
      }
    } catch (error) {
      setRequestPaymentModalOpen(false);
      setInitialState();
    }
  }

  function closeModal() {
    setIsExitModalOpen(false);
    setTimeout(() => {
      setRequestPaymentModalOpen(false);
    }, 0);
  }
  function addNote() {
    setShowSendRequestButton(false);
    setShowNoteField(true);
    setTimeout(() => {
      const element = document.getElementById("note-textarea");
      element?.focus();
      element?.click();
      element?.scrollIntoView();
    }, 0);
  }
  function removeNote() {
    setNote("");
    setShowSendRequestButton(false);
    setShowNoteField(false);
  }
  function toggleAdvancedOptions() {
    setShowSendRequestButton(false);
    setShowAdvancedOptions(!showAdvancedOptions);
  }
  function openExitModalDesktop() {
    // setIsExitModalOpen(true);
    setRequestPaymentModalOpen(false);
    setInitialState();
    // setComingFromExitModal(true);
  }
  function openExitModalMobile() {
    setIsExitModalOpen(true);
    // setRequestPaymentModalOpen(false);
    setComingFromExitModal(true);
  }

  function cancelExitModal() {
    setIsExitModalOpen(false);
    setRequestPaymentModalOpen(true);
  }

  function closeModalFromExitModal() {
    closeModal();
    setInitialState();
  }

  function contactOnClickHandler(contact: string | IContact) {
    contact = typeof contact === "string" ? contact.trim() : contact;
    if (
      typeof contact === "string" &&
      contact !== "" &&
      !validatePhoneNumber(contact) &&
      !validateEmail(contact)
    ) {
      setValidationError(true);
      return;
    }
    if (contact === newContact) {
      setRecipient(newContact);
      setStage("2");
    } else {
      setRecipient(contact);
      setStage("2");
    }
  }

  let title;
  if (type === "invoice") {
    title = "Request a Payment";
  } else if (type === "quote") {
    if (!quoteToEdit && !quoteToConvert) {
      title = "Create Quote";
    } else if (!!quoteToEdit && !quoteToConvert) {
      title = "Edit Quote";
    } else if (!quoteToEdit && !!quoteToConvert) {
      title = "Request a Payment";
    }
  }

  useEffect(() => {
    if (type === "quote" && !quoteToConvert) {
      if (firstName !== "") {
        const formattedPrice = numberWithCommas(totalPrice.toFixed(2));
        setSubmitButtonText(`$${formattedPrice} - Send quote to ${firstName}`);
      } else {
        const formattedPrice = numberWithCommas(totalPrice.toFixed(2));
        setSubmitButtonText(
          `$${formattedPrice} - Send quote to ${getCustomerDisplayName(
            recipientName
          )}`
        );
      }
    } else {
      if (firstName !== "") {
        const formattedPrice = numberWithCommas(totalPrice.toFixed(2));
        setSubmitButtonText(`Request $${formattedPrice} from ${firstName}`);
      } else {
        const formattedPrice = numberWithCommas(totalPrice.toFixed(2));
        setSubmitButtonText(
          `Request $${formattedPrice} from ${getCustomerDisplayName(
            recipientName
          )}`
        );
      }
    }
  }, [totalPrice, firstName]);

  function addNewContactHandler() {
    setComingFromAddContactModal(true);
    setRequestPaymentModalOpen(false);
    setAddRecipientModalOpen(true);
  }

  function titleCase(string: string) {
    return string[0]?.toUpperCase() + string.slice(1).toLowerCase();
  }

  function checkForErrors() {
    if (totalPrice >= 1000000) {
      setOneMillionError(true);
      return;
    }
    if (
      totalPrice === 0 &&
      items.length > 0 &&
      !!items[0].itemName &&
      !!items[0].itemPrice
    ) {
      setShowSendRequestButton(true);
      return;
    }
    if (
      totalPrice === 0 ||
      !totalPrice ||
      (items.length === 1 && !items[0].itemName)
    ) {
      setNoPriceError(true);
      return;
    } else if (!!tax.taxAmount && !tax.taxName) {
      setNoTaxNameError(true);
      return;
    } else if (!tax.taxAmount && !!tax.taxName) {
      setNoTaxAmountError(true);
      return;
    }

    setShowSendRequestButton(true);
  }

  const heightAutoStage2 =
    items.length > 7 || (showNoteField && items.length > 4) ? true : false;

  function buttonCss() {
    if (stage === "1") {
      return "justify-end";
    }
    if (stage === "2") {
      if (showSendRequestButton) {
        return "justify-end";
      } else {
        return "justify-between";
      }
    } else {
      return "";
    }
  }

  function removeTax() {
    setShowTaxField(false);
    setTax({ taxName: "", taxAmount: "" });
  }
  function removeDiscount() {
    setShowDiscountField(false);
    setDiscount("");
    setDiscountType("%");
  }

  async function voidQuote() {
    const response = await cancelQuote(
      quoteToEdit?.id,
      stripe_connect_account_id
    );
    if (response === "voided") {
      const newOpenQuotes = openQuotes?.filter((i) => {
        return i.id !== quoteToEdit.id;
      });
      if (newOpenQuotes) setOpenQuotes(newOpenQuotes);

      const newQuotes = {
        ...quotes,
        open: newOpenQuotes,
      };
      setQuotes(newQuotes);

      setRequestPaymentModalOpen(false);
    }
    return response;
  }

  async function onSubmitFunction(requestType: string) {
    setIsSubmitProcessing(true);
    await sendRequest(requestType);
    setIsSubmitProcessing(false);
  }

  const getEmailSecondaryButtonText = () => {
    if (type === "invoice") {
      return "Email Request";
    }
    if (quoteToConvert) {
      return "Email Request";
    } else {
      return "Email Quote";
    }
  };

  const getTextPrimaryButtonText = () => {
    if (type === "invoice") {
      return "Text Request";
    }
    if (quoteToConvert) {
      return "Text Request";
    } else {
      return "Text Quote";
    }
  };

  const getEmailOrTextPrimaryButtonText = () => {
    if (type === "invoice") {
      return "Request";
    }
    if (quoteToConvert) {
      return "Request";
    } else {
      return "Quote";
    }
  };

  const searchElement = (
    <div className="relative w-full sm:mb-4 sm:-mt-2">
      <DynamicInput
        id="payment-recipient"
        name="payment-recipient"
        placeHolder="Name, phone number, or email"
        fieldValidator={recipientValidate}
        handleLocalEdits={onChangeHandler}
        value={searchedContact}
        edit={true}
        validationOn={true}
        onSubmit={handleEnterKeydown}
        externalErrorMessage={alertMessage}
      />
    </div>
  );

  const customButtons = (
    <>
      {/* mobile */}
      <div
        className={`sm:hidden flex w-full p-3 justify-end align-middle items-center border-t ${
          downloadAppOnIphoneWithNoHomeButton ? "pb-8" : ""
        } ${stage === "1" ? "border-t" : ""}`}
      >
        {stage === "1" && (
          <SecondaryButton
            text="Create New Contact"
            size="lg"
            onClickFunc={addNewContactHandler}
          />
        )}
        {stage === "2" && (
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between border-b pb-2">
              <PrimaryLink
                text={`${showNoteField ? "Remove" : "Add"} note`}
                size="md"
                onClickFunc={
                  showNoteField ? () => removeNote() : () => addNote()
                }
              />
              <PrimaryLink
                text={showAdvancedOptions ? "Show less" : "Advanced options"}
                size="md"
                onClickFunc={toggleAdvancedOptions}
              />
            </div>

            <div
              className={`${
                who_pays_stripe_fees === "customer" || showAdvancedOptions
                  ? "flex"
                  : "hidden"
              } flex-col space-y-2 pt-4 pb-2`}
            >
              <div
                className={`${
                  who_pays_stripe_fees === "customer" || showAdvancedOptions
                    ? "flex"
                    : "hidden"
                } flex-row justify-between items-center`}
              >
                <div className="flex text-sm-medium text-gray-700">
                  Subtotal
                </div>
                <div className="flex">
                  ${numberWithCommas(subtotal.toFixed(2))}
                </div>
              </div>
              {showAdvancedOptions && showDiscountField && (
                <div className="flex flex-row justify-between items-center">
                  <div className="flex text-sm-medium text-gray-700">
                    <div
                      onClick={removeDiscount}
                      className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                    >
                      <XCircleIcon className="text-gray-600 w-5 h-5" />
                    </div>
                    <div>Discount</div>
                  </div>
                  <DiscountDropdown
                    discount={discount}
                    setDiscount={setDiscount}
                    discountType={discountType}
                    setDiscountType={setDiscountType}
                  />
                </div>
              )}
              {showAdvancedOptions && !showDiscountField && (
                <PrimaryLink
                  text="Add Discount"
                  size="md"
                  onClickFunc={() => setShowDiscountField(true)}
                />
              )}
              {/* {showAdvancedOptions ? (
                            showDiscountField ? (
                              <div className="flex flex-row justify-between items-center">
                                <div className="flex text-sm-medium">
                                  <div
                                    onClick={removeDiscount}
                                    className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                                  >
                                    <XCircleIcon className="text-gray-600 w-5 h-5" />
                                  </div>
                                  <div>Discount</div>
                                </div>
                                <DiscountDropdown
                                  discount={discount}
                                  setDiscount={setDiscount}
                                  discountType={discountType}
                                  setDiscountType={setDiscountType}
                                />
                              </div>
                            ) : (
                              <PrimaryLink
                                text="Add Discount"
                                size="md"
                                onClickFunc={() => setShowDiscountField(true)}
                              />
                            )
                          ) : (
                            <></>
                          )} */}
              {showAdvancedOptions && showTaxField && (
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex text-sm-medium text-gray-700 mr-4">
                      <div
                        onClick={removeTax}
                        className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                      >
                        <XCircleIcon className="text-gray-600 w-5 h-5" />
                      </div>
                      <div>Tax</div>
                    </div>
                    <TaxNameAndAmount
                      tax={tax}
                      setTax={setTax}
                      noTaxNameError={noTaxNameError}
                      setNoTaxNameError={setNoTaxNameError}
                      setShowTaxField={setShowTaxField}
                      noTaxAmountError={noTaxAmountError}
                      setNoTaxAmountError={setNoTaxAmountError}
                      setShowSendRequestButton={setShowSendRequestButton}
                    />
                  </div>
                  {(noTaxNameError || noTaxAmountError) && (
                    <>
                      <div className="flex flex row w-full">
                        <div className="flex w-1/5"></div>
                        <div className="flex flex-row w-4/5">
                          <div className="w-2/3 relative mt-1">
                            <div className="flex text-sm-normal text-red-600">
                              Required
                            </div>
                          </div>
                          <div className="w-1/3 relative"></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {showAdvancedOptions && !showTaxField && (
                <PrimaryLink
                  text="Add Tax"
                  size="md"
                  onClickFunc={() => setShowTaxField(true)}
                />
              )}
              {/* {showAdvancedOptions ? (
                            showTaxField ? (
                              <div className="flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                  <div className="flex text-sm-medium-normal mr-4">
                                    <div
                                      onClick={removeTax}
                                      className="z-40 inset-y-0 left-0 flex items-center pr-2 cursor-pointer"
                                    >
                                      <XCircleIcon className="text-gray-600 w-5 h-5" />
                                    </div>
                                    <div>Tax</div>
                                  </div>
                                  <TaxNameAndAmount
                                    tax={tax}
                                    setTax={setTax}
                                    noTaxNameError={noTaxNameError}
                                    setNoTaxNameError={setNoTaxNameError}
                                    setShowTaxField={setShowTaxField}
                                    noTaxAmountError={noTaxAmountError}
                                    setNoTaxAmountError={setNoTaxAmountError}
                                    setShowSendRequestButton={
                                      setShowSendRequestButton
                                    }
                                  />
                                </div>
                                {(noTaxNameError || noTaxAmountError) && (
                                  <>
                                    <div className="flex flex row w-full">
                                      <div className="flex w-1/5"></div>
                                      <div className="flex flex-row w-4/5">
                                        <div className="w-2/3 relative mt-1">
                                          <div className="flex text-sm-normal text-red-600">
                                            Required
                                          </div>
                                        </div>
                                        <div className="w-1/3 relative"></div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <PrimaryLink
                                text="Add Tax"
                                size="md"
                                onClickFunc={() => setShowTaxField(true)}
                              />
                            )
                          ) : (
                            <></>
                          )} */}

              {who_pays_stripe_fees === "customer" &&
                (type !== "quote" || !!quoteToConvert) && (
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex text-sm-medium text-gray-700">
                      Processing Fee
                    </div>
                    <div>${numberWithCommas(fee.toFixed(2))}</div>
                  </div>
                )}
            </div>

            {showSendRequestButton ? (
              <PaymentsSubmitButton
                loading={isSubmitProcessing}
                disabled={isSubmitProcessing}
                text={submitButtonText}
                size="lg"
                onClickFunc={() => onSubmitFunction(requestType)}
                customStyle={`w-full mt-2 ${
                  isSubmitProcessing ? "sm:w-60" : "sm:w-auto"
                }`}
              />
            ) : (
              <div className="flex flex-row w-full pt-2">
                <div className="w-1/2 sm:hidden flex flex-row items-center">
                  <div className="text-gray-600 text-sm-normal mr-2">
                    {type === "quote" && !quoteToConvert && "Est."} Total
                  </div>
                  <div className="flex text-gray-900 text-h2-bold">
                    ${numberWithCommas(totalPrice.toFixed(2))}
                  </div>
                </div>
                <div className="w-1/2 sm:hidden flex flex-row items-center justify-between">
                  {emailOrText === "both" ? (
                    <>
                      <SecondaryButton
                        text="Email"
                        size="lg"
                        onClickFunc={() => {
                          checkForErrors();
                          setRequestType("email");
                        }}
                        customStyle="w-1/2 mr-2"
                      />
                      <PrimaryButton
                        text="Text"
                        size="lg"
                        onClickFunc={() => {
                          checkForErrors();
                          setRequestType("text");
                        }}
                        customStyle="w-1/2"
                      />
                    </>
                  ) : (
                    <PrimaryButton
                      text={`${titleCase(emailOrText)}`}
                      size="lg"
                      onClickFunc={() => {
                        checkForErrors();
                        setRequestType(emailOrText);
                      }}
                      customStyle="w-full"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* desktop */}
      <div className="hidden sm:flex sm:justify-end align-middle items-center mt-4">
        <div className={`flex w-full ${buttonCss()}`}>
          {stage === "1" && (
            <SecondaryButton
              text="Create New Contact"
              size="lg"
              onClickFunc={addNewContactHandler}
            />
          )}
          {stage === "2" &&
            (showSendRequestButton ? (
              <PaymentsSubmitButton
                loading={isSubmitProcessing}
                disabled={isSubmitProcessing}
                text={submitButtonText}
                size="lg"
                onClickFunc={() => onSubmitFunction(requestType)}
                customStyle={`w-full ${
                  isSubmitProcessing ? "sm:w-60" : "sm:w-auto"
                }`}
              />
            ) : (
              <>
                <div className="flex w-full justify-between">
                  <div className="flex">
                    <PrimaryLink
                      text={`${showNoteField ? "Remove" : "Add"} note`}
                      size="md"
                      onClickFunc={
                        showNoteField ? () => removeNote() : () => addNote()
                      }
                      customStyle="ml-2 mr-4"
                    />
                    {!!quoteToEdit && (
                      <RedLink
                        onClickFunc={voidQuote}
                        customStyle="sm:flex hidden text-red-600"
                        text="Delete"
                        size="md"
                      />
                    )}
                  </div>
                  <div className="flex">
                    {emailOrText === "both" ? (
                      <>
                        <SecondaryButton
                          text={getEmailSecondaryButtonText()}
                          size="lg"
                          onClickFunc={() => {
                            checkForErrors();
                            setRequestType("email");
                          }}
                          customStyle="mr-2"
                        />
                        <PrimaryButton
                          text={getTextPrimaryButtonText()}
                          size="lg"
                          onClickFunc={() => {
                            checkForErrors();
                            setRequestType("text");
                          }}
                        />
                      </>
                    ) : (
                      <PrimaryButton
                        text={`
                                    ${titleCase(
                                      emailOrText
                                    )} ${getEmailOrTextPrimaryButtonText()}`}
                        size="lg"
                        onClickFunc={() => {
                          checkForErrors();
                          setRequestType(emailOrText);
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <ActionModal
        open={isExitModalOpen}
        setOpen={isMobile ? openExitModalMobile : openExitModalDesktop}
        header="Leave"
        subheader="Your changes will be lost"
        primaryButtonText="Leave"
        secondaryButtonText="Stay here"
        primaryButtonFunction={closeModalFromExitModal}
        secondaryButtonFunction={cancelExitModal}
      />
      {addRecipientModalOpen && (
        <AddContactModal
          addContactModalOpen={addRecipientModalOpen}
          setAddContactModalOpen={setAddRecipientModalOpen}
          setRequestPaymentModalOpen={setRequestPaymentModalOpen}
          setStage={setStage}
          setRecipient={setRecipient}
          setRecipientName={setRecipientName}
          setComingFromAddContactModal={setComingFromAddContactModal}
        />
      )}

      <UtilityModal
        isOpen={requestPaymentModalOpen}
        setIsOpen={setRequestPaymentModalOpen}
        title={title || ""}
        showCancelButton={false}
        showSubmitButton={false}
        submitButtonText={submitButtonText}
        submitButtonId="edit-post-submit"
        isSubmitDisabled={isSubmitProcessing}
        onSubmitHandler={sendRequest}
        onCancelHandler={isMobile ? openExitModalMobile : openExitModalDesktop}
        showLoader={true}
        width="sm:w-800 sm:max-w-full"
        height={`${stage === "1" && "sm:h-auto"} ${
          stage === "2" && heightAutoStage2
            ? "sm:max-h-900 sm:overflow-y-auto"
            : "sm:max-h-900"
        }`}
        customButtons={customButtons}
        elementStickyToTop={stage === "1" && searchElement}
        headerBorder={true}
        footerBorder={true}
      >
        <>
          {contacts &&
            contacts.length !== 0 &&
            (stage === "1" ? (
              <Stage1
                type={type}
                displayedContacts={displayedContacts}
                newContact={newContact}
                contactOnClickHandler={contactOnClickHandler}
              />
            ) : (
              <Stage2
                type={type}
                recipient={recipient}
                recipientName={recipientName}
                note={note}
                setNote={setNote}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                items={items}
                setItems={setItems}
                setStage={setStage}
                setSearchedContact={setSearchedContact}
                emailOrText={emailOrText}
                setEmailOrText={setEmailOrText}
                setDisplayedContacts={setDisplayedContacts}
                noPriceError={noPriceError}
                setNoPriceError={setNoPriceError}
                setShowSendRequestButton={setShowSendRequestButton}
                lineItems={lineItems}
                setLineItems={setLineItems}
                showNoteField={showNoteField}
                showDiscountField={showDiscountField}
                setShowDiscountField={setShowDiscountField}
                showTaxField={showTaxField}
                setShowTaxField={setShowTaxField}
                discount={discount}
                setDiscount={setDiscount}
                discountType={discountType}
                setDiscountType={setDiscountType}
                tax={tax}
                setTax={setTax}
                showAdvancedOptions={showAdvancedOptions}
                addNote={addNote}
                removeNote={removeNote}
                fee={fee}
                setFee={setFee}
                subtotal={subtotal}
                setSubtotal={setSubtotal}
                noTaxNameError={noTaxNameError}
                setNoTaxNameError={setNoTaxNameError}
                noTaxAmountError={noTaxAmountError}
                setNoTaxAmountError={setNoTaxAmountError}
                comingFromExitModal={comingFromExitModal}
                setComingFromExitModal={setComingFromExitModal}
                editingQuote={!!quoteToEdit}
                convertingQuoteToInvoice={!!quoteToConvert}
                quoteToConvert={quoteToConvert}
                quotesFromDb={quotesFromDb}
                setOneMillionError={setOneMillionError}
                oneMillionError={oneMillionError}
                noteError={noteError}
                setNoteError={setNoteError}
              />
            ))}

          {contacts &&
            contacts.length === 0 &&
            (stage === "1" ? (
              <EmptyStateRequestPayment
                type={type}
                onCancelHandler={onCancelHandler}
                setAddRecipientModalOpen={setAddRecipientModalOpen}
                setRequestPaymentModalOpen={setRequestPaymentModalOpen}
                newContact={newContact}
                setNewContact={setNewContact}
                setRecipient={setRecipient}
                setStage={setStage}
                setError={setValidationError}
                setSearchedContact={setSearchedContact}
                searchedContact={searchedContact}
                contactOnClickHandler={contactOnClickHandler}
              />
            ) : (
              <Stage2
                type={type}
                recipient={recipient}
                recipientName={recipientName}
                note={note}
                setNote={setNote}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                items={items}
                setItems={setItems}
                setStage={setStage}
                setSearchedContact={setSearchedContact}
                emailOrText={emailOrText}
                setEmailOrText={setEmailOrText}
                setDisplayedContacts={setDisplayedContacts}
                noPriceError={noPriceError}
                setNoPriceError={setNoPriceError}
                setShowSendRequestButton={setShowSendRequestButton}
                lineItems={lineItems}
                setLineItems={setLineItems}
                showNoteField={showNoteField}
                showDiscountField={showDiscountField}
                setShowDiscountField={setShowDiscountField}
                showTaxField={showTaxField}
                setShowTaxField={setShowTaxField}
                discount={discount}
                setDiscount={setDiscount}
                tax={tax}
                setTax={setTax}
                showAdvancedOptions={showAdvancedOptions}
                addNote={addNote}
                removeNote={removeNote}
                fee={fee}
                setFee={setFee}
                subtotal={subtotal}
                setSubtotal={setSubtotal}
                noTaxNameError={noTaxNameError}
                setNoTaxNameError={setNoTaxNameError}
                noTaxAmountError={noTaxAmountError}
                setNoTaxAmountError={setNoTaxAmountError}
                comingFromExitModal={comingFromExitModal}
                setComingFromExitModal={setComingFromExitModal}
                editingQuote={!!quoteToEdit}
                convertingQuoteToInvoice={!!quoteToConvert}
                quotesFromDb={quotesFromDb}
                setOneMillionError={setOneMillionError}
                oneMillionError={oneMillionError}
                noteError={noteError}
                setNoteError={setNoteError}
              />
            ))}
        </>
      </UtilityModal>
    </>
  );
}
