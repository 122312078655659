import { XCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import ItemsDropdown from "../ItemsDropdown/ItemsDropdown";

export interface ItemFieldProps {
  item: any;
  id: number;
  onChangeHandler: any;
  maxId: number;
  onBlurPriceHandler: any;
  addItemModalOpen: boolean;
  setAddItemModalOpen: (arg0: boolean) => void;
  setAddItemId: (arg0: number) => void;
  items: any[];
  setItems: (arg0: any) => void;
  deleteItemsActive: boolean;
  lineItems: any[];
  addLineItem: any;
  setShowSendRequestButton: (arg0: boolean) => any;
  setNoPriceError: (arg0: boolean) => void;
  noPriceError: boolean;
  comingFromExitModal: boolean;
  setComingFromExitModal: (arg0: boolean) => void;
}

export default function ItemField({
  item,
  id,
  onChangeHandler,
  maxId,
  onBlurPriceHandler,
  addItemModalOpen,
  setAddItemModalOpen,
  setAddItemId,
  items,
  setItems,
  deleteItemsActive,
  lineItems,
  addLineItem,
  setShowSendRequestButton,
  noPriceError,
  setNoPriceError,
  comingFromExitModal,
  setComingFromExitModal,
}: ItemFieldProps) {
  function startAddItem(id: number) {
    setShowSendRequestButton(false);
    setAddItemModalOpen(true);
    setAddItemId(id);
  }

  function removeItem(id: number) {
    const newItems = [...items];
    newItems.splice(id, 1);
    setItems(newItems);
    setShowSendRequestButton(false);
  }

  const names = lineItems?.map((item: any) => {
    return item.item;
  });
  const [lineItemNames, setLineItemNames] = useState(names);

  useEffect(() => {
    const newNames = lineItems?.map((item: any) => {
      return item.item;
    });
    setLineItemNames(newNames);
  }, [lineItems]);

  return (
    <>
      <div className="flex flex-row sm:-mt-0.5">
        <div className="flex w-full">
          <div className="relative w-2/3 -mb-0.5">
            {deleteItemsActive && items.length > 1 && (
              <div
                onClick={() => removeItem(id)}
                className="z-100-important absolute inset-y-0 left-0 flex items-center pl-3 pr-6 cursor-pointer"
              >
                <XCircleIcon className="text-gray-600 w-5 h-5" />
              </div>
            )}
            <label htmlFor="item-name" className="sr-only">
              Item
            </label>
            <input
              type="text"
              name="item-name"
              id="item-name"
              className={`relative block sm:hidden w-full rounded-none border border-gray-300 bg-white text-sm-normal focus:outline-none focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-body-normal sm:shadow-sm ${
                id === 0 && "rounded-tl-md"
              } ${id === maxId && "rounded-bl-md"} ${
                deleteItemsActive && items.length > 1 && "pl-10"
              } ${noPriceError ? "border-red-600" : "border-gray-300"}`}
              placeholder="Item"
              readOnly={true}
              value={item.itemName}
              onClick={() => startAddItem(id)}
            />
            <div className="sm:block hidden">
              <ItemsDropdown
                id={id}
                maxId={maxId}
                items={items}
                onChangeHandler={onChangeHandler}
                addLineItem={addLineItem}
                removeItem={removeItem}
                lineItemNames={lineItemNames}
                noPriceError={noPriceError}
                setNoPriceError={setNoPriceError}
                comingFromExitModal={comingFromExitModal}
                setComingFromExitModal={setComingFromExitModal}
              />
            </div>
          </div>
          <div className="relative w-1/3 -mb-0.5">
            <label htmlFor="price" className="sr-only">
              Price
            </label>
            <div className="pointer-events-none absolute h-full text-gray-500 left-2 z-50 flex items-center">
              <span
                className={`z-50 ${
                  !item.itemPrice || item.itemPrice === 0
                    ? "text-gray-500"
                    : "text-gray-900"
                } text-body-normal`}
              >
                $
              </span>
            </div>
            <input
              type="text"
              pattern="\d*"
              name="price"
              id={`price-${id}`}
              className={`relative block w-full -ml-0.5 rounded-none border border-gray-300 bg-white text-body-normal focus:outline-none focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-body-normal sm:shadow-sm pl-5 ${
                id === 0 && "rounded-tr-md"
              } ${id === maxId && "rounded-br-md"} ${
                noPriceError ? "border-red-600" : "border-gray-300"
              }`}
              // style={{ paddingLeft: "20px" }}
              placeholder="0.00"
              onChange={(e) => onChangeHandler(e, id, "price")}
              onBlur={(e) => onBlurPriceHandler(e, id)}
              value={item.itemPrice}
            />
          </div>
        </div>
      </div>
    </>
  );
}
