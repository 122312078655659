import { XCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  formatPhoneNumber,
  validateEmail,
  validatePhoneNumberNoPlusOne,
} from "../../contacts/helpers/formatPhoneNumber";
import saveNewContact from "../../contacts/helpers/saveNewContact";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";

export interface AddContactModalProps {
  addContactModalOpen: boolean;
  setAddContactModalOpen: (arg0: boolean) => void;
  setRequestPaymentModalOpen: (arg0: boolean) => void;
  setStage: (arg0: string) => void;
  setRecipient: (arg0: any) => void;
  setRecipientName: (arg0: string) => void;
  setComingFromAddContactModal: (arg0: boolean) => void;
}

export default function AddContactModal({
  addContactModalOpen,
  setAddContactModalOpen,
  setRequestPaymentModalOpen,
  setStage,
  setRecipient,
  setRecipientName,
  setComingFromAddContactModal,
}: AddContactModalProps) {
  const blankContact = {
    profile_photo: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
    notes: "",
    city: "",
    state: "",
    zipcode: "",
  };

  const [newContactData, setNewContactData] = useState(blankContact);
  const [error, setError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [zipCodeError, setZipCodeError] = useState<string>("");
  const [noPhoneOrEmailError, setNoPhoneOrEmailError] =
    useState<boolean>(false);
  const { profileInfo, contacts, setContacts } = useContext(DesktopContext);
  const { slug } = profileInfo || {};

  async function onSaveAndAddHandler() {
    let error = false;
    if (!newContactData.first_name) {
      setError("Required");
      error = true;
      return;
    }
    if (
      newContactData.email.trim() !== "" ||
      newContactData.phone_number.trim() !== ""
    ) {
      if (newContactData.email.trim() !== "") {
        if (!validateEmail(newContactData.email)) {
          setEmailError("Invalid email address");
          error = true;
        }
        if (!validateEmail(newContactData.email)) {
          setEmailError(
            newContactData.email === "" ? "Required" : "Invalid email address"
          );
          error = true;
        }
      }
      if (newContactData.phone_number.trim() !== "") {
        if (!validatePhoneNumberNoPlusOne(newContactData.phone_number)) {
          setPhoneError("Invalid phone number");
          error = true;
        }
        if (!validatePhoneNumberNoPlusOne(newContactData.phone_number)) {
          setPhoneError(
            newContactData.phone_number === ""
              ? "Required"
              : "Invalid phone number"
          );
          error = true;
        }
      }
      if (newContactData.zipcode.trim() !== "") {
        if (newContactData.zipcode.length != 5) {
          setZipCodeError("Invalid zip code");
          error = true;
        }
      }
    } else {
      setNoPhoneOrEmailError(true);
      error = true;
    }
    if (error === true) return;

    const newContactArray = [newContactData];
    const newContactResponse = await saveNewContact(slug, newContactArray);
    const newContactId = newContactResponse?.contact_list[0]?.id;
    const newContact = { ...newContactData, id: newContactId };
    const newContacts = [...contacts, newContact];

    setContacts(newContacts);
    setAddContactModalOpen(false);
    setRequestPaymentModalOpen(true);
    setComingFromAddContactModal(true);
    setStage("2");
    setRecipient(newContact);
    const fullName = `${newContactData.first_name} ${newContactData?.last_name}`;
    setRecipientName(fullName);
  }

  function onCancelHandler() {
    setAddContactModalOpen(false);
    setRequestPaymentModalOpen(true);
  }

  return (
    <>
      <UtilityModal
        isOpen={addContactModalOpen}
        setIsOpen={setAddContactModalOpen}
        title="Add Recipient"
        showCancelButton={true}
        secondaryButtonText="Back"
        showSubmitButton={true}
        submitButtonText="Save and Add"
        submitButtonId="save-and-add-new-contact"
        onSubmitHandler={onSaveAndAddHandler}
        onCancelHandler={onCancelHandler}
        showLoader={true}
      >
        {noPhoneOrEmailError && (
          <div className="w-full rounded-lg bg-red-50 mt-3">
            <div className="w-full p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-6 w-6 text-red-600" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm-medium text-red-800">
                    Phone number or email required
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <form className="flex flex-col space-y-4 sm:mb-4">
          <div className="flex sm:flex-row flex-col w-full sm:space-y-0 space-y-4">
            <div className="flex flex-col sm:w-1/2 w-full sm:mr-4">
              <DynamicInput
                name="first_name"
                value={newContactData.first_name}
                type="text"
                label="First Name"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    first_name: e.first_name,
                  });
                  setError("");
                }}
                validationOn
                autoFocus={true}
                externalErrorMessage={error}
                setExternalErrorMessage={setError}
              />
            </div>
            <div className="flex sm:w-1/2 w-full">
              <DynamicInput
                name="last_name"
                value={newContactData.last_name}
                type="text"
                label="Last Name"
                edit={true}
                handleLocalEdits={(e: any) => {
                  // setIsSaveDisable(false);
                  setNewContactData({
                    ...newContactData,
                    last_name: e.last_name,
                  });
                }}
                validationOn
              />
            </div>
          </div>
          <div>
            <DynamicInput
              name="phone_number"
              value={formatPhoneNumber(newContactData.phone_number)}
              type="text"
              label="Phone Number"
              edit={true}
              handleLocalEdits={(e: any) => {
                // setIsSaveDisable(false);
                setNewContactData({
                  ...newContactData,
                  phone_number: e.phone_number,
                });
                setPhoneError("");
                setNoPhoneOrEmailError(false);
              }}
              validationOn
            />
            <div className="text-sm-normal text-red-600 mt-1">{phoneError}</div>
          </div>
          <div>
            <DynamicInput
              name="email"
              value={newContactData.email}
              type="text"
              label="Email"
              edit={true}
              handleLocalEdits={(e: any) => {
                // setIsSaveDisable(false);
                setNewContactData({
                  ...newContactData,
                  email: e.email.trim(),
                });
                setEmailError("");
                setNoPhoneOrEmailError(false);
              }}
              validationOn
            />
            <div className="text-sm-normal text-red-600 mt-1">{emailError}</div>
          </div>
          <div>
            <DynamicInput
              name="address"
              value={newContactData.address}
              type="text"
              label="Address"
              edit={true}
              handleLocalEdits={(e: any) => {
                // setIsSaveDisable(false);
                setNewContactData({
                  ...newContactData,
                  address: e.address,
                });
              }}
              validationOn={true}
            />
          </div>
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4">
            <div className="flex sm:w-1/3 w-full sm:mr-4">
              <DynamicInput
                name="city"
                value={newContactData.city}
                type="text"
                label="City"
                edit={true}
                handleLocalEdits={(e: any) => {
                  // setIsSaveDisable(false);
                  setNewContactData({
                    ...newContactData,
                    city: e.city,
                  });
                }}
                validationOn
              />
            </div>
            <div className="flex sm:w-1/3 w-full sm:mr-4">
              <DynamicInput
                name="state"
                value={newContactData.state}
                type="text"
                label="State"
                edit={true}
                handleLocalEdits={(e: any) => {
                  // setIsSaveDisable(false);
                  setNewContactData({
                    ...newContactData,
                    state: e.state,
                  });
                }}
                validationOn
                isSmall
              />
            </div>
            <div className="flex sm:w-1/3 w-full">
              <DynamicInput
                name="zipcode"
                value={newContactData.zipcode}
                type="text"
                label="Zip Code"
                edit={true}
                handleLocalEdits={(e: any) => {
                  // setIsSaveDisable(false);
                  setNewContactData({
                    ...newContactData,
                    zipcode: e.zipcode,
                  });
                }}
                validationOn
                isSmall
              />
            </div>
            <div className="text-sm-normal text-red-600 mt-1">{phoneError}</div>
          </div>
        </form>
      </UtilityModal>
    </>
  );
}
