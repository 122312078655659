import React from "react";
import { IContact } from "../../contacts/Contact/types";
import StackedList from "../../../common/StackedList/StackedList";

export interface PaymentsContactTableProps {
  type: string;
  displayedContacts: IContact[];
  newContact: string;
  contactOnClickHandler: (_contact: IContact | string) => void;
}

export default function PaymentsContactTable({
  type,
  displayedContacts,
  newContact,
  contactOnClickHandler,
}: PaymentsContactTableProps): React.ReactElement {
  return (
    <div className="flex flex-col overflow-auto">
      <StackedList
        data={displayedContacts}
        onItemClickHandler={contactOnClickHandler}
      />

      {displayedContacts.length === 0 && newContact !== "" && (
        <div
          className="flex hover:bg-blue-50 px-8 py-4 cursor-pointer border-b border-gray-300"
          onClick={() => contactOnClickHandler(newContact)}
        >
          <div className="flex flex-col justify-center w-full">
            <div className="text-sm-normal flex justify-start">
              Send {type === "invoice" ? "payment request" : "quote"} to
            </div>
            <div className="text-sm-medium flex justify-start">
              {newContact}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
