import React from "react";
import PaymentsContactTable from "../PaymentsContactsTable/PaymentsContactsTable";
import { IContact } from "../../contacts/Contact/types";

export interface Stage1Props {
  type: string;
  displayedContacts: IContact[];
  newContact: any;
  contactOnClickHandler: any;
}
export default function Stage1({
  type,
  displayedContacts,
  newContact,
  contactOnClickHandler,
}: Stage1Props): React.ReactElement {
  return (
    <>
      <div className="relative flex flex-col justify-between w-full sm:h-300 -mt-3 sm:mt-0">
        <PaymentsContactTable
          type={type}
          displayedContacts={displayedContacts}
          newContact={newContact}
          contactOnClickHandler={contactOnClickHandler}
        />
      </div>
    </>
  );
}
