import React from "react";
import Image from "next/image";
import { formatPhoneNumber as getFormattedPhoneNumber } from "../../primaryrender/contacts/helpers/formatPhoneNumber";
import { getOptimizedCloudFrontImageUrl } from "../../primaryrender/editor/utils";
import { getNameFirstLetter } from "../../primaryrender/payments/utils";
import {
  ChevronRightIcon,
  ClockIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/solid";
import SecondaryButton from "../../uiwrappers/SecondaryButton/SecondaryButton";

export interface StackedListProps {
  data: any[];
  onItemClickHandler?: (_item: any | string) => void;
  renderItemTitle?: (_item: any) => React.ReactNode;
  renderItemDescription?: (_item: any) => React.ReactNode;
  renderItemAdditionalInfo?: (_item: any) => React.ReactNode;
  renderItemExpandableContent?: (_item: any) => React.ReactNode;
  enableIcons?: boolean;
  formatPhoneNumber?: boolean;
  secondaryButtonHandler?: (_item: any, _index: number) => void;
  secondaryButtonText?: string;
  secondaryButtonDisabled?: (_item: any) => boolean;
  itemStyle?: string;
  displayChevronRight?: boolean;
}

const StackedList: React.FC<StackedListProps> = ({
  data,
  onItemClickHandler,
  renderItemTitle,
  renderItemDescription,
  renderItemAdditionalInfo,
  renderItemExpandableContent,
  enableIcons = false,
  formatPhoneNumber = true,
  secondaryButtonHandler,
  secondaryButtonText = "",
  secondaryButtonDisabled,
  itemStyle = "",
  displayChevronRight = false,
}) => {
  function getAvatar(item: any): React.ReactNode {
    const { first_name, last_name, profile_photo } = item;

    const initials =
      first_name &&
      last_name &&
      first_name.split("")[0] + last_name.split("")[0];
    const firstNameFirstLetter = getNameFirstLetter(first_name);
    const lastNameFirstLetter = getNameFirstLetter(last_name);

    if (profile_photo && profile_photo !== '""') {
      const optimized_profile_photo =
        getOptimizedCloudFrontImageUrl(profile_photo);
      return (
        <div className="relative h-10 w-10 rounded-full object-cover flex-shrink-0">
          <Image
            layout={"fill"}
            src={optimized_profile_photo}
            alt={`${first_name} ${last_name} avatar`}
            objectFit="cover"
          />
        </div>
      );
    } else if (!profile_photo && !initials && firstNameFirstLetter)
      return (
        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 flex-shrink-0">
          <span className="text-body-medium text-white">
            {firstNameFirstLetter}
          </span>
        </span>
      );
    else if (
      !profile_photo &&
      !initials &&
      !firstNameFirstLetter &&
      lastNameFirstLetter
    )
      return (
        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 flex-shrink-0">
          <span className="text-body-medium text-white">
            {lastNameFirstLetter}
          </span>
        </span>
      );
    else if (firstNameFirstLetter && lastNameFirstLetter)
      return (
        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 flex-shrink-0">
          <span className="text-body-medium text-white">{initials}</span>
        </span>
      );
    else
      return (
        <div className="h-10 w-10">
          <UserIcon className="w-10 h-10 p-3 rounded-full bg-gray-500 text-white" />
        </div>
      );
  }

  const renderStatus = (item: any) => {
    if (item?.status) {
      switch (item.status) {
        case "pending":
          return (
            <div className="flex items-center gap-1.5">
              <ClockIcon className="w-6 h-6 text-amber-600" />
              <p className="text-gray-500">Pending</p>
            </div>
          );
        default:
          return null;
      }
    } else return null;
  };

  return (
    <ul role="list" className="divide-y divide-gray-200">
      {data?.map((item: any, index: number) => (
        <li
          key={item.id}
          onClick={() => onItemClickHandler && onItemClickHandler(item)}
          className={`py-4 ${itemStyle} ${
            onItemClickHandler && "cursor-pointer hover:bg-blue-50"
          }`}
          tabIndex={index}
        >
          <div className="flex justify-between sm:items-center">
            <div className="flex gap-3 items-center">
              {getAvatar(item)}

              <div>
                <div className="flex items-center gap-3">
                  {renderItemTitle ? (
                    renderItemTitle(item)
                  ) : (
                    <p className="text-sm-medium text-gray-900">
                      {item.first_name} {item.last_name}
                    </p>
                  )}

                  {renderStatus(item)}
                </div>

                {renderItemDescription && renderItemDescription(item)}

                {item?.email && (
                  <div className="flex items-center gap-1.5 text-sm-normal text-gray-500">
                    {enableIcons && (
                      <MailIcon className="w-6 h-6 text-gray-400" />
                    )}
                    <p className="truncate">{item.email}</p>
                  </div>
                )}

                {item?.phone_number && (
                  <div className="flex items-center gap-1.5 text-sm-normal text-gray-500">
                    {enableIcons && (
                      <PhoneIcon className="w-6 h-6 text-gray-400" />
                    )}
                    <p>
                      {formatPhoneNumber
                        ? getFormattedPhoneNumber(item.phone_number)
                        : item.phone_number}
                    </p>
                  </div>
                )}

                {secondaryButtonHandler && (
                  <SecondaryButton
                    text={secondaryButtonText}
                    size="xs"
                    onClickFunc={() => secondaryButtonHandler(item, index)}
                    customStyle="mt-3 sm:hidden"
                    disabled={
                      secondaryButtonDisabled && secondaryButtonDisabled(item)
                    }
                  />
                )}

                {/* {renderItemExpandableContent && (
                  <div className="hidden sm:block">
                    {renderItemExpandableContent(item)}
                  </div>
                )} */}
              </div>
            </div>

            <div className="inline-flex items-center">
              {renderItemAdditionalInfo && (
                <div className="sm:text-body-normal text-sm sm:mt-0 mt-1">
                  {renderItemAdditionalInfo(item)}
                </div>
              )}

              {secondaryButtonHandler && (
                <SecondaryButton
                  text={secondaryButtonText}
                  size="sm"
                  onClickFunc={() => secondaryButtonHandler(item, index)}
                  customStyle="hidden sm:block"
                  disabled={
                    secondaryButtonDisabled && secondaryButtonDisabled(item)
                  }
                />
              )}

              {displayChevronRight && (
                <ChevronRightIcon className="h-5 w-5 text-gray-400 self-center" />
              )}
            </div>
          </div>

          {renderItemExpandableContent && (
            <div>{renderItemExpandableContent(item)}</div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default StackedList;
